<script>

import { HomeIcon, MailIcon } from "vue-feather-icons";

/**
 * Auth-re-password-three component
 */
export default {
  data() {
    return {
      input2sql: {
        username: '',
      },
      showStatus: false,
      noUserStatus: false,
      errorStatus: false,
    };
  },
  components: {
    HomeIcon,
    MailIcon,
  },

  methods: {

    updatePassword() {


      this.$vs.loading({
        container: '#div-with-loading',
        //container: this.$refs.loginButton.$el,
        scale: '0.45',
      });



      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/update-password',
        data: this.input2sql
      }).then((res) => {
        console.log(res.data)

        this.$vs.loading.close("#div-with-loading > .con-vs-loading");

        if (res.data.status === 'success') {
          this.showStatus = true
          this.$vs.notify({
            time: 8000,
            title: 'Успех',
            text: 'письмо отправлено',
            color: 'success'
          });
        } else if (res.data.userExists === 'no') {
          this.noUserStatus = true
          this.$vs.notify({
            time: 4000,
            title: 'Ошибка',
            text: res.data.status,
            color: 'danger'
          });
        } else {
          this.errorStatus = true
          this.$vs.notify({
            time: 4000,
            title: 'Ошибка',
            text: res.data.status,
            color: 'danger'
          });
        }
      });


    }


  },

};
</script>

<template>
  <div>

    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <section class="bg-half-170 ">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Восстановить пароль</h4>

                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="text-muted">
                        Введите свою почту и мы пришлем ссылку для восстановления пароля.
                      </p>
                      <div class="form-group">
                        <label>Email
                          <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input type="email" class="form-control pl-5" placeholder="Введите ваш Email" name="email"
                            v-model="input2sql.username" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <!--
                      <button class="btn btn-primary btn-block">Восстановить пароль</button>
                      -->

                      <vs-button id="div-with-loading" ref="updatePasswordButton" size="xl" type="button"
                        class="btn btn-primary btn-block vs-con-loading__container" @click="updatePassword">
                        Войти
                      </vs-button>


                    </div>
                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2">Помните пароль ?</small>
                        <router-link to="/auth/login" class="text-dark font-weight-bold">Войти</router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

  </div>
</template>
